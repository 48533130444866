import Link from 'next/link'
import { useRouter } from 'next/router'
import * as Icon from 'components/DesignSystemIcons'
import { MastheadGroup, MastheadIcon, MastheadTitle } from 'components/Masthead'
import { useModal } from 'components/ModalKit'
import StoresSearchModal from 'components/pages/explore/StoresSearchModal'

interface Props {
  header: string
  isFilterPage?: boolean
}

const StoreListHeader = ({ isFilterPage, header }: Props) => {
  const router = useRouter()
  const { openModal } = useModal()

  const toggleOpenSearch = () => {
    openModal(
      <StoresSearchModal
        onSubmitPath="/explore/stores/filtered"
      />
    )
  }

  return (
    <MastheadGroup>
      {!!isFilterPage && (
        <Icon.Button
          onClick={() => router.push({
            pathname: '/explore/stores',
          })}
          data-testid="back-button"
        >
          <Icon.ChevronLeft />
        </Icon.Button>
      )}
      <MastheadTitle>{header}</MastheadTitle>
      <MastheadIcon onClick={toggleOpenSearch} type="button">
        <Icon.Button as="span" css={{ transform: 'rotate(90deg)' }} data-testid="search-button">
          <Icon.Aura css={{ backgroundColor: '$grey100' }}>
            <Icon.Sliders />
          </Icon.Aura>
        </Icon.Button>
      </MastheadIcon>
      <Link
        href={{
          pathname: '/explore/map',
        }}
        passHref
        prefetch={false}
      >
        <MastheadIcon type="button">
          <Icon.Button as="span">
            <Icon.Aura css={{ backgroundColor: '$grey100' }}>
              <Icon.MapMarker />
            </Icon.Aura>
          </Icon.Button>
        </MastheadIcon>
      </Link>
    </MastheadGroup>
  )
}

export default StoreListHeader
